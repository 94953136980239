import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import { convertToRaw, EditorState, ContentState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import * as DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../components/UI/Input/Input.Editor.scss';

import { LanguageSelect } from 'page-section-library';
import OverlaySpinner from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import Button from '../../../components/UI/Button/Button';
import Toaster from '../../../components/UI/Toaster/Toaster';
import Modal from '../../../components/UI/Modal/Modal';
import Input from '../../../components/UI/Input/Input';

import SettingsService from '../../../api/Settings';
import languages from '../../../shared/languages';

import styles from './CampaignTermsEditPage.module.scss';

const dynamicTextValues = [
  {
    editorHtml:
      '<span style="color: rgb(255,111,0);"><strong>{{campaignName}}</strong></span>',
    rawHtml: '{{productName}}',
    viewHtml: '<strong>Campaign Name</strong>',
    key: 'campaignName',
    name: 'Campaign Name',
  },
  {
    editorHtml:
      '<span style="color: rgb(255,111,0);"><strong>{{rewardName}}</strong></span>',
    rawHtml: '{{providerName}}',
    viewHtml: '<strong>Reward Name</strong>',
    key: 'providerName',
    name: 'Reward Name',
  },
  {
    editorHtml:
      '<span style="color: rgb(255,111,0);"><strong>{{brandName}}</strong></span>',
    rawHtml: '{{brandName}}',
    viewHtml: '<strong>Brand Name</strong>',
    key: 'brandName',
    name: 'Brand Name',
  },
  {
    editorHtml:
      '<span style="color: rgb(255,111,0);"><strong>{{offerCompanyName}}</strong></span>',
    rawHtml: '{{offerCompanyName}}',
    viewHtml: '<strong>Offer Company Name</strong>',
    key: 'offerCompanyName',
    name: 'Offer Company Name',
  },
  {
    editorHtml:
      '<span style="color: rgb(255,111,0);"><strong>{{offerBrandName}}</strong></span>',
    rawHtml: '{{offerBrandName}}',
    viewHtml: '<strong>Offer Brand Name</strong>',
    key: 'offerBrandName',
    name: 'Offer Brand Name',
  },
];

const replaceMultiple = (string, replacements) => {
  let updatedString = string;
  replacements.forEach((replacement) => {
    updatedString = updatedString.replaceAll(
      replacement.find,
      replacement.value,
    );
  });
  return updatedString;
};

const DynamicTextPlugin = ({ insertHtmlString }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="rdw-block-wrapper" aria-label="rdw-block-control">
      <div
        className={`rdw-dropdown-wrapper rdw-block-dropdown ${styles.customDropDown}`}
        aria-label="rdw-dropdown"
      >
        <div
          className="rdw-dropdown-selectedtext"
          title="Placeholders"
          onClick={() => setOpen(!open)}
        >
          <span>Dynamic Text</span>
          <div className={`rdw-dropdown-caretto${open ? 'close' : 'open'}`} />
        </div>
        {open && (
          <ul className="rdw-dropdown-optionwrapper">
            {dynamicTextValues.map((dynamicText) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={dynamicText.key}
                onClick={() => {
                  insertHtmlString(dynamicText.editorHtml);
                  setOpen(false);
                }}
                className="rdw-dropdownoption-default placeholder-li"
              >
                {dynamicText.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

/**
 * This component services 2 paths
 *  campaignId is optional.
 *
 * If termsId == "new" then we create
 *  a new blank form (draft terms).
 *
 * Creating a new draft is only allowed
 *  if an existing draft does not exist
 */

const getListUrl = (campaignId) => {
  if (!campaignId) {
    return '/settings/manage-terms/';
  }
  return `/campaigns/edit/${campaignId}/manage-terms/`;
};

const CampaignTermsEditPage = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const { termsId, campaignId } = useParams();
  const [termsHtml, setTermsHtml] = useState();
  const [language, setLanguage] = useState('en');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [published, setPublished] = useState(true);
  const [editorTouched, setEditorTouched] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [descriptor, setDescriptor] = useState('');
  const [descriptorError, setDescriptorError] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [showAsHtml, setShowAsHtml] = useState(false);

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  useEffect(() => {
    if (!termsHtml?.[language]) {
      setTermsHtml((state) => ({
        ...state,
        [language]: {
          id: null,
          language,
          translation: '',
        },
      }));
      setEditorState(EditorState.createEmpty());
      return;
    }

    const transformedHtml = replaceMultiple(
      termsHtml[language].translation,
      dynamicTextValues.map((value) => ({
        find: value.rawHtml,
        value: value.editorHtml,
      })),
    );

    const contentBlock = htmlToDraft(transformedHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [language, termsHtml]);

  useEffect(() => {
    const loadPage = async () => {
      const requester = campaignId
        ? SettingsService.getTermsCampaign
        : SettingsService.getTermsBase;

      try {
        const result = await requester(campaignId, termsId);
        if (!result.data.translations) {
          process.env.NODE_ENV !== 'production' &&
            console.error('No data found');
          navigate(getListUrl(campaignId));
        }
        setTermsHtml(result.data.translations);
        setPublished(result.data.published);
        setCampaignName(result?.data?.campaignName || null);
        setEditorTouched(false);
        // Update dynamic values with data from API
        dynamicTextValues.forEach((value, idx) => {
          if (result?.data?.[value.key]) {
            dynamicTextValues[idx].viewHtml = `<strong>${
              result?.data?.[value.key]
            }</strong>`;
          }
        });
      } catch (error) {
        process.env.NODE_ENV !== 'production' && console.error(error);
        navigate(getListUrl(campaignId));
      }
    };

    loadPage();
  }, [campaignId, termsId, navigate]);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    !editorTouched && setEditorTouched(true);
  };

  const returnEditorStateAsHtmlString = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const rawHtml = draftToHtml(rawContentState);

    const transformedHtml = replaceMultiple(
      rawHtml,
      dynamicTextValues.map((value) => ({
        find: value.editorHtml,
        value: value.rawHtml,
      })),
    );

    return DOMPurify.sanitize(transformedHtml);
  };

  const onLanguageChange = (newLanguage) => {
    if (!published) {
      setTermsHtml((state) => ({
        ...state,
        [language]: {
          ...state[language],
          translation: returnEditorStateAsHtmlString(),
        },
      }));
    }

    if (published && !termsHtml[newLanguage]?.translation) {
      setTermsHtml((state) => ({
        ...state,
        [newLanguage]: {
          ...state[newLanguage],
          translation: `<p><strong>No translation exists for this language.</strong></p> ${
            campaignId
              ? '<p><strong>The default version will be used instead.</strong></p>'
              : ''
          }`,
        },
      }));
    }

    setTimeout(() => {
      setLanguage(newLanguage);
    });
  };

  const saveDraft = async () => {
    setLoading(true);

    const updatedTermsHtml = {
      ...termsHtml,
      [language]: {
        ...termsHtml[language],
        translation: returnEditorStateAsHtmlString(),
      },
    };

    setTermsHtml(updatedTermsHtml);

    const requester = campaignId
      ? SettingsService.updateTermsCampaign
      : SettingsService.updateTermsBase;

    await requester(campaignId, termsId, updatedTermsHtml)
      .then(() => {
        setToast({
          show: true,
          class: 'success',
          header: 'Draft saved successfully.',
        });
        setEditorTouched(false);
        setLoading(false);
      })
      .catch((error) => {
        process.env.NODE_ENV !== 'production' && console.error(error);
        setToast({
          show: true,
          class: 'error',
          header: 'There was an error saving your draft. Please try again.',
        });
        setLoading(false);
      });
  };

  const publish = async () => {
    if (!descriptor) {
      setDescriptorError('Please provide a description of the changes');
      return;
    }

    setLoading(true);
    setDescriptorError('');

    const requester = campaignId
      ? SettingsService.publishTermsCampaign
      : SettingsService.publishTermsBase;

    await requester(campaignId, termsId, { descriptor })
      .then(() => {
        setToast({
          show: true,
          class: 'success',
          header: 'Terms and Conditions published successfully.',
        });
        setPublished(true);
        setLoading(false);
        setShowConfirmationModal(false);
      })

      .catch((error) => {
        process.env.NODE_ENV !== 'production' && console.error(error);
        setToast({
          show: true,
          class: 'error',
          header:
            'There was an error publishing your Terms and Conditions. Please try again.',
        });
        setLoading(false);
        setShowConfirmationModal(false);
      });
  };

  const insertHtmlString = (htmlTextString) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithHtml = htmlToDraft(`${htmlTextString} <span></span>`);
    const contentStateFromHtml = ContentState.createFromBlockArray(
      contentStateWithHtml.contentBlocks,
      contentStateWithHtml.entityMap,
    );

    const newContentState = Modifier.replaceWithFragment(
      contentState,
      editorState.getSelection(),
      contentStateFromHtml.getBlockMap(),
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-fragment',
    );
    setEditorState(newEditorState);
  };

  const switchEditorHtml = () => {
    if (showAsHtml) {
      setShowAsHtml(false);
      return;
    }

    setTermsHtml((state) => ({
      ...state,
      [language]: {
        ...state[language],
        translation: returnEditorStateAsHtmlString(),
      },
    }));
    setShowAsHtml(true);
  };

  return (
    <div className={styles.campaignTermsEditPage}>
      <Toaster toast={toast} closeToast={closeToast} />

      <Container fluid="lg" className="mt-5">
        <main>
          {published ? (
            <div>
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <h2 className="mb-3">
                  {campaignId
                    ? `Terms and Conditions for: ${campaignName}`
                    : 'Base Terms and Conditions'}
                </h2>
                <div className="mb-3">
                  <LanguageSelect
                    availableLanguages={Object.keys(languages)}
                    currentLanguage={language}
                    onLanguageChange={onLanguageChange}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-5">
                <h4 className="mb-0">Version {termsId}</h4>
                <Button
                  clicked={() => navigate(getListUrl(campaignId))}
                  additionalClasses={['mr-0']}
                >
                  Return to list view
                </Button>
              </div>
            </div>
          ) : (
            <div className="mb-4">
              <div className="d-flex justify-content-between mb-3">
                <h2 className="mb-3">Edit Terms and Conditions</h2>
                <LanguageSelect
                  availableLanguages={Object.keys(languages)}
                  currentLanguage={language}
                  onLanguageChange={onLanguageChange}
                />
              </div>
              <div className={`${styles.container}`}>
                <h4>Updating terms and conditions</h4>
                <p>
                  Updating your Terms and Conditions will replace the current
                  version with your new version. Published Terms and Conditions
                  cannot be deleted but they can be replaced.
                </p>
                <p className="mb-4">
                  Please be sure to translate your Terms and Conditions into all
                  required languages <strong>before</strong> publishing. Please
                  note only the language of the text can be edited when adding a
                  translation. All other changes must be made to the English
                  version.
                </p>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <h5>How to use Dynamic Text:</h5>
                    <p>
                      Dynamic text is used to conveniently add in the Campaign,
                      Brand and reward names for a particular campaign. Dynamic
                      text items will be highlighted when added correctly.
                    </p>
                    <p>Use the dynamic text dropdown to add them in.</p>
                  </div>

                  <div className="col-12 col-sm-6">
                    <h5>Dynamic Text Items:</h5>
                    <ul>
                      {dynamicTextValues.map((value) => (
                        <li key={value.key}>
                          <strong>
                            {`{{${
                              value.name.charAt(0).toLowerCase() +
                              value.name.slice(1).replaceAll(' ', '')
                            }}}`}
                          </strong>{' '}
                          for the {value.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!termsHtml && <OverlaySpinner />}

          {termsHtml && published && (
            <div className="mb-5">
              {parse(
                DOMPurify.sanitize(
                  replaceMultiple(
                    termsHtml[language].translation,
                    dynamicTextValues.map((value) => ({
                      find: value.rawHtml,
                      value: value.viewHtml,
                    })),
                  ),
                ),
              )}
            </div>
          )}

          {termsHtml && !published && (
            <div className={styles.container}>
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <h3 className="mb-3">
                  {campaignId ? (
                    <>
                      <span>Terms and Conditions for:</span>
                      <span className="d-block">{campaignName}</span>
                    </>
                  ) : (
                    'Base Terms and Conditions'
                  )}
                </h3>

                <div className="mb-3 btnContainer">
                  <Dropdown className="d-inline-flex">
                    <Dropdown.Toggle
                      data-test-id="campaign-more-actions-dropdown"
                      className="btnDefault p-3"
                    >
                      <FontAwesomeIcon fixedWidth icon={faEllipsisH} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={switchEditorHtml}>
                        {showAsHtml ? 'Switch to Editor' : 'Switch to HTML'}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(getListUrl(campaignId))}
                      >
                        Return to list view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Button
                    disabled={!editorTouched}
                    isLoading={loading && editorTouched}
                    clicked={saveDraft}
                  >
                    Save Draft
                  </Button>
                  <Button
                    disabled={editorTouched}
                    clicked={() => setShowConfirmationModal(true)}
                  >
                    Publish
                  </Button>
                </div>
              </div>
              <div className={`${styles.innerContainer}`}>
                {showAsHtml ? (
                  <div className="mb-5">
                    {parse(
                      DOMPurify.sanitize(
                        replaceMultiple(
                          termsHtml[language].translation,
                          dynamicTextValues.map((value) => ({
                            find: value.rawHtml,
                            value: value.viewHtml,
                          })),
                        ),
                      ),
                    )}
                  </div>
                ) : (
                  <Editor
                    name="terms"
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    toolbarCustomButtons={[
                      <DynamicTextPlugin insertHtmlString={insertHtmlString} />,
                    ]}
                    toolbar={{
                      options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'list',
                        'textAlign',
                        'history',
                      ],
                      inline: { inDropdown: false },
                      list: { inDropdown: false },
                      textAlign: { inDropdown: false },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                    customStyleMap={{
                      redBackground: {
                        backgroundColor: 'red',
                      },
                      greenBackground: {
                        backgroundColor: 'green',
                      },
                    }}
                    ref={inputRef}
                  />
                )}
              </div>
            </div>
          )}
        </main>
      </Container>

      <Modal
        title="Publish Terms and Conditions"
        cancel="Back"
        save="Confirm"
        loading={loading}
        handleSave={publish}
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
      >
        <p>
          The updated T&C&apos;s will be active for all current campaigns,
          please confirm the updates have been made for all relevant languages
          before proceeding
        </p>

        <Input
          elementType="textarea"
          label="Description of changes (Max 200 characters)"
          additionalClasses="formInput formInput--bordered formInput--smaller"
          value={descriptor}
          changed={(e) => {
            setDescriptor(e.target.value);
          }}
          touched
          error={descriptorError}
          elementConfig={{
            maxLength: '200',
            rows: 6,
          }}
        />
      </Modal>
    </div>
  );
};

export default CampaignTermsEditPage;
